import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { checkAnim } from "../util/animationTrigger";

import Project from "../components/Project";
import imagegallery from "../assets/projects/image-gallery.png";
import chatApp from "../assets/projects/chat-app.png";
import tasklistM from "../assets/projects/task-list.png";
import tasklistS from "../assets/projects/eth-task-list.png";
import minesweeper from "../assets/projects/minesweeper-won.png";
import breakout from "../assets/projects/breakout.png";
import hotTopics from "../assets/projects/hot-topics.png";
import portfolio0 from "../assets/projects/portfolio0.png";

import currentsea from "../assets/projects/currentsea.png";
import squareone from "../assets/projects/squareone.png";
import thano5 from "../assets/projects/thano5.png";
import nearmons from "../assets/projects/near-mons.png";
import solace_ui from "../assets/projects/solace-ui.png";
import claim_verification from "../assets/projects/claim-verification.png";

import js from "../assets/skills/languages/js-logo.png";
import ts from "../assets/skills/languages/ts-logo.png";
import html from "../assets/skills/languages/html-logo.png";
import css from "../assets/skills/languages/css-logo.png";
import sass from "../assets/skills/languages/sass-logo.png";
import sol from "../assets/skills/languages/solidity-logo.png";
import py from "../assets/skills/languages/py-logo.png";
import sql from "../assets/skills/languages/sql-logo.png";
import csharp from "../assets/skills/languages/csharp-logo.png";
import c from "../assets/skills/languages/c-logo.png";
import java from "../assets/skills/languages/java-logo.png";
import cpp from "../assets/skills/languages/cpp-logo.png";
import as from "../assets/skills/languages/as-logo.png";

import node from "../assets/skills/frameworks/node-logo.png";
import express from "../assets/skills/frameworks/express-logo.png";
import react from "../assets/skills/frameworks/react-logo.png";
import redux from "../assets/skills/frameworks/redux-logo.png";
import gatsby from "../assets/skills/frameworks/gatsby-logo.png";
import jest from "../assets/skills/frameworks/jest-logo.png";
import jquery from "../assets/skills/frameworks/jquery-logo.png";
import bootstrap from "../assets/skills/frameworks/bootstrap-logo.png";
import bulma from "../assets/skills/frameworks/bulma-logo.png";
import mui from "../assets/skills/frameworks/mui-logo.png";
import truffle from "../assets/skills/frameworks/truffle-logo.png";
import web3 from "../assets/skills/frameworks/web3-logo.png";
import styled_components from "../assets/skills/frameworks/styled-components-logo.png";
import tailwind from "../assets/skills/frameworks/tailwind-logo.png";
import ethers from "../assets/skills/frameworks/ethers-logo.png";

import git from "../assets/skills/devTools/git-logo.png";
import npm from "../assets/skills/devTools/npm-logo.png";
import vscode from "../assets/skills/devTools/vscode-logo.png";
import mongo from "../assets/skills/devTools/mongo-logo.png";
import mysql from "../assets/skills/devTools/mysql-logo.png";
import docker from "../assets/skills/devTools/docker-logo.png";
import ganache from "../assets/skills/devTools/ganache-logo.png";
import postman from "../assets/skills/devTools/postman-logo.png";
import heroku from "../assets/skills/devTools/heroku-logo.png";
import netlify from "../assets/skills/devTools/netlify-logo.png";
import firebase from "../assets/skills/devTools/firebase-logo.png";
import gcp from "../assets/skills/devTools/gcp-logo.png";
import infura from "../assets/skills/devTools/infura-logo.png";
import aws from "../assets/skills/devTools/aws-logo.png";
import alchemy from "../assets/skills/devTools/alchemy-logo.png";

import "./ProjectSection.css";

function ProjectSection() {
  const personalProjects = [
    {
      name: "HotTopics",
      img: hotTopics,
      info: (
        <h6 className="project-description assisting-text text-align-left">
          A social media clone app built using React, Redux, and Firebase.
        </h6>
      ),
      liveUrl: "https://hot-tops.firebaseapp.com/",
      codeUrl: "https://github.com/DannyFung97/social-media-app-client",
      stack: [react, redux, firebase, mui],
    },
    {
      name: "Chat App",
      img: chatApp,
      info: (
        <h6 className="project-description assisting-text text-align-left">
          A public chat app with authentication and login functionality built
          using Firebase.
        </h6>
      ),
      liveUrl: "https://eager-mestorf-cb3f2a.netlify.app/",
      codeUrl: "https://github.com/DannyFung97/chat-app",
      stack: [react, firebase],
    },
    {
      name: "Image Gallery",
      img: imagegallery,
      info: (
        <h6 className="project-description assisting-text text-align-left">
          An image gallery made from scratch using the Gatsby framework. Images
          are my own and are stored on the Cloudinary account.
        </h6>
      ),
      liveUrl: "https://dannygallery.netlify.app/",
      codeUrl: "https://github.com/DannyFung97/image-gallery-project",
      stack: [gatsby, bulma, netlify],
    },
    {
      name: "Portfolio 0",
      img: portfolio0,
      info: (
        <h6 className="project-description assisting-text text-align-left">
          A stylistic iteration of my portfolio that is now archived.
        </h6>
      ),
      liveUrl: "https://cocky-jackson-870852.netlify.app/",
      codeUrl: "https://github.com/DannyFung97/personal-portfolio-0",
      stack: [react, bulma, netlify],
    },
    {
      name: "Randomized Breakout",
      img: breakout,
      info: (
        <h6 className="project-description assisting-text text-align-left">
          A game project built based on the Breakout arcade game.
        </h6>
      ),
      liveUrl: "https://dannyfung97.github.io/randomized-breakout/",
      codeUrl: "https://github.com/DannyFung97/randomized-breakout",
      stack: [react, redux],
    },
    {
      name: "Minesweeper",
      img: minesweeper,
      info: (
        <h6 className="project-description assisting-text text-align-left">
          A game created using TypeScript and SASS on the React framework.
        </h6>
      ),
      liveUrl: "https://condescending-mayer-d3975f.netlify.app/",
      codeUrl: "https://github.com/DannyFung97/minesweeper-ts",
      stack: [ts, sass, netlify],
    },
    {
      name: "Task List w/ MongoDB",
      img: tasklistM,
      info: (
        <h6 className="project-description assisting-text text-align-left">
          A web app that stores user-created tasks and allows check offs.
        </h6>
      ),
      liveUrl: "https://github.com/DannyFung97/task-list-react-project",
      codeUrl: "https://github.com/DannyFung97/task-list-react-project",
      stack: [react, redux, node, mongo, bootstrap],
    },
    {
      name: "Task List w/ Solidity",
      img: tasklistS,
      info: (
        <h6 className="project-description assisting-text text-align-left">
          An app that stores user-created tasks on the Ethereum blockchain.
        </h6>
      ),
      liveUrl: "https://github.com/DannyFung97/eth-react-task-manager",
      codeUrl: "https://github.com/DannyFung97/eth-react-task-manager",
      stack: [react, redux, sol, truffle, ganache, web3],
    },
    {
      name: "NEAR mons",
      img: nearmons,
      info: (
        <div className="text">
          <h6 className="project-description assisting-text text-align-left">
            A smart contract made for the NEAR blockchain that produces NFTs as creature collectibles.
            Users can create new creatures by having two parent creatures fuse to form a child creature, 
            which will inherit its parents' skills and attributes.
          </h6>
          <h6 className="project-description assisting-text text-align-left">
            This was a project I was passionate about and developed during the NEAR Developer Program late March 2021. The new language was definitely a challenge and this project was
            also the first time where I took testing seriously and improved my skills in unit testing. After 5 days of continuous work, I was able to 
            code all the functions, compile the contract, pass all written tests, and deploy it on the testnet.
          </h6>
        </div>
      ),
      liveUrl: null,
      codeUrl: "https://github.com/DannyFung97/near-mons",
      stack: [as],
    },
  ];

  const groupProjects = [
    {
      name: 'Solace',
      img: solace_ui,
      info: (
        <div className="text">
        <h6 className="project-description assisting-text text-align-left">
          A major web app designed for users to interact with the decentralized Solace Insurance protocol. 
          Users can buy insurance policies for their investments, buy the Solace governance token, and provide 
          those tokens as risk capital in exchange for rewards.
        </h6>
        <h6 className="project-description assisting-text text-align-left">
          Since early 2021, I have been continuously prototyping and iterating on this React application.
          I was the only web developer on the team at the beginning so I had to be the one to lay out the groundwork.
          Through this project, I had to learn a lot of new technologies, and hold multiple meetings 
          with my colleagues across most of the departments in order to make sure it meets with everyone's expectations.
        </h6>
      </div>
      ),
      liveUrl: 'https://app.solace.fi/',
      codeUrl: 'https://github.com/solace-fi/solace-client',
      stack: [react, alchemy, ethers, styled_components, ts, aws, tailwind],
    },
    {
      name: 'Medicare Claim Verification',
      img: claim_verification,
      info: (
        <div className="text">
        <h6 className="project-description assisting-text text-align-left">
          A web app prototype that observes the idea of verifying patient claims and prevent healthcare fraud using distributed ledger technology.
        </h6>
        <h6 className="project-description assisting-text text-align-left">
          This was a project I worked on with some peers during my time at the San Diego Supercomputer Center.
          At the time, there were no plans in place to actually launch this prototype into the mainstream as 
          it was developed primarily for research and concept.
        </h6>
      </div>
      ),
      liveUrl: null,
      codeUrl: 'https://github.com/sdsc-blocklab/Medicare-Claim-Verification',
      stack: [react, bootstrap, sol, truffle, ganache, web3],
    },
    {
      name: "CurrentSea",
      img: currentsea,
      info: (
        <div className="text">
          <h6 className="project-description assisting-text text-align-left">
            A web app that keeps track of a user's budget, records their
            financial transactions, and displays exchange rates for different 33
            world currencies.
          </h6>
          <h6 className="project-description assisting-text text-align-left">
            This was a project for my Software Engineering class during college in Fall 2018. In a
            team of 9, my role as one of the software architects was to develop
            the user interface, ensure product quality during code review, and
            implement the backend server. It was the first time I used React and
            Node in a major group project, so as a result, my social and
            technical skills as a developer improved significantly.
          </h6>
        </div>
      ),
      liveUrl: null,
      codeUrl: "https://github.com/izzafkhan/currentSea",
      stack: [react, node, express, sql],
    },
    {
      name: "SquareOne",
      img: squareone,
      info: (
        <div className="text">
          <h6 className="project-description assisting-text text-align-left">
            A web app in which users pick a map of any sport and edit player
            positions, draw strategic paths, and save created plays based on the
            map.
          </h6>
          <h6 className="project-description assisting-text text-align-left">
            This was a project for my Interaction Design class during college in Winter 2019. In a
            team of 3, I took the initiative to start the project since I wanted
            to try driving the project this time. While the other members
            created the docs for the project, I programmed the majority of the
            application, such as the drawing and saving features. I also managed
            the application's deployment on heroku.
          </h6>
        </div>
      ),
      liveUrl: null,
      codeUrl: "https://github.com/DannyFung97/SquareOne",
      stack: [jquery, heroku],
    },
    {
      name: "Thano5 Web Components",
      img: thano5,
      info: (
        <div className="text">
          <h6 className="project-description assisting-text text-align-left">
            A library of custom web components directed at front-end
            development. Thoroughly tested and documented to maintain integrity
            over time.
          </h6>
          <h6 className="project-description assisting-text text-align-left">
            This was a project for my Advanced Software Engineering class during college in Spring 2019.
            Unlike the CurrentSea and SquareOne group projects, I did not have
            the role of a programmer. I was part of the Tools &amp; Utility
            team, which was responsible for researching developer tools, writing
            docs, creating presentations, and organizing project structure.
          </h6>
        </div>
      ),
      liveUrl: null,
      codeUrl: "https://github.com/ucsd-cse112-sp19/thanO5",
      stack: [js, html, css],
    },
  ];

  document.addEventListener("scroll", function (e) {
    checkAnim("work", "fadeIn");
    let el = document.getElementById("work");
    let scrollPos = window.scrollY;
    let elementPos =
      window.pageYOffset + el.getBoundingClientRect().top + el.offsetHeight / 4;
    if (scrollPos + window.innerHeight > elementPos) {
      let projects = el.getElementsByClassName("project");
      for (let i = 0; i < projects.length; i++) {
        if (!projects[i].classList.contains("anim-done")) {
          projects[i].classList.add("anim-done");
          appear(projects[i], i);
        }
      }
    }
  });

  function appear(project, i) {
    project.animate(
      [
        { opacity: 0, transform: "rotateY(90deg)" },
        { opacity: 1, transform: "rotateY(0deg)" },
      ],
      {
        duration: 300,
        delay: 200 + 100 * i,
        fill: "forwards",
      }
    );
  }

  return (
    <section id="work" className="section-container opacity-zero">
      <Container fluid>
        <Col md={{ span: 10, offset: 1 }} className="col-container">
          <div className="text">
            <h2 className="section-title">Work</h2>
            <h5 className="subtitle">
              These are projects that I've worked on:
            </h5>
          </div>
          <div className="text">
            <h3 className="skill-subtitle">
              <span className="display-icon">
                <i className={`fas fa-users fa-sm`}></i>
              </span>
              Team Projects
            </h3>
          </div>
          <ProjectList projects={groupProjects} />
          <h3 className="skill-subtitle">
              <span className="display-icon">
                <i className={`fas fa-user fa-sm`}></i>
              </span>
              Personal Projects
            </h3>
          <ProjectList projects={personalProjects} />
        </Col>
      </Container>
    </section>
  );
}

function ProjectList({ projects }) {
  return (
    <Row>
      {projects.map((project, j) => {
        return (
          <Col md={4} key={j}>
            <Project project={project} />
          </Col>
        );
      })}
    </Row>
  );
}

export default ProjectSection;
