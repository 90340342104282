import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { checkAnim } from "../util/animationTrigger";

import js from "../assets/skills/languages/js-logo.png";
import ts from "../assets/skills/languages/ts-logo.png";
import html from "../assets/skills/languages/html-logo.png";
import css from "../assets/skills/languages/css-logo.png";
import sass from "../assets/skills/languages/sass-logo.png";
import sol from "../assets/skills/languages/solidity-logo.png";
import py from "../assets/skills/languages/py-logo.png";
import sql from "../assets/skills/languages/sql-logo.png";
import csharp from "../assets/skills/languages/csharp-logo.png";
import c from "../assets/skills/languages/c-logo.png";
import java from "../assets/skills/languages/java-logo.png";
import cpp from "../assets/skills/languages/cpp-logo.png";
import as from "../assets/skills/languages/as-logo.png";

import node from "../assets/skills/frameworks/node-logo.png";
import express from "../assets/skills/frameworks/express-logo.png";
import react from "../assets/skills/frameworks/react-logo.png";
import redux from "../assets/skills/frameworks/redux-logo.png";
import gatsby from "../assets/skills/frameworks/gatsby-logo.png";
import jest from "../assets/skills/frameworks/jest-logo.png";
import jquery from "../assets/skills/frameworks/jquery-logo.png";
import bootstrap from "../assets/skills/frameworks/bootstrap-logo.png";
import bulma from "../assets/skills/frameworks/bulma-logo.png";
import mui from "../assets/skills/frameworks/mui-logo.png";
import truffle from "../assets/skills/frameworks/truffle-logo.png";
import web3 from "../assets/skills/frameworks/web3-logo.png";
import vue from "../assets/skills/frameworks/vue-logo.png";
import ethers from "../assets/skills/frameworks/ethers-logo.png";
import styled_components from "../assets/skills/frameworks/styled-components-logo.png";
import tailwind from "../assets/skills/frameworks/tailwind-logo.png";
import nextjs from "../assets/skills/frameworks/nextjs-logo.png";

import git from "../assets/skills/devTools/git-logo.png";
import npm from "../assets/skills/devTools/npm-logo.png";
import vscode from "../assets/skills/devTools/vscode-logo.png";
import mongo from "../assets/skills/devTools/mongo-logo.png";
import mysql from "../assets/skills/devTools/mysql-logo.png";
import docker from "../assets/skills/devTools/docker-logo.png";
import ganache from "../assets/skills/devTools/ganache-logo.png";
import postman from "../assets/skills/devTools/postman-logo.png";
import heroku from "../assets/skills/devTools/heroku-logo.png";
import netlify from "../assets/skills/devTools/netlify-logo.png";
import firebase from "../assets/skills/devTools/firebase-logo.png";
import gcp from "../assets/skills/devTools/gcp-logo.png";
import infura from "../assets/skills/devTools/infura-logo.png";
import aws from "../assets/skills/devTools/aws-logo.png";
import graphql from "../assets/skills/devTools/graphql-logo.png";
import alchemy from "../assets/skills/devTools/alchemy-logo.png";
import vercel from "../assets/skills/devTools/vercel-logo.png";

import "./SkillSection.css";

function SkillSection() {
  const frontendLanguages = [
    [
      { logo: ts, name: "TypeScript", focus: false },
      { logo: sass, name: "SASS", focus: false },
      { logo: js, name: "JavaScript", focus: true },
      { logo: css, name: "CSS", focus: true },
      { logo: html, name: "HTML", focus: true },
    ],
  ];

  const frontendFrameworks = [
    [
      { logo: react, name: "React", focus: true },
      { logo: nextjs, name: "Next.js", focus: true },
      { logo: redux, name: "Redux", focus: true },
      { logo: styled_components, name: "Styled Components", focus: true },
      { logo: tailwind, name: "Tailwind CSS", focus: true },
      { logo: gatsby, name: "Gatsby", focus: false },
      { logo: jquery, name: "jQuery" },
      { logo: vue, name: "Vue", focus: false },
      { logo: bootstrap, name: "Bootstrap", focus: true },
      { logo: bulma, name: "Bulma", focus: true },
      { logo: mui, name: "Material-UI", focus: false },
    ],
    // [

    //   // { logo: jest, name: "Jest" },
    // ],
  ];

  const backendLanguages = [
    [
      { logo: sql, name: "SQL", focus: true },
      { logo: py, name: "Python", focus: false },
      { logo: java, name: "Java", focus: false },
      { logo: as, name: "AssemblyScript", focus: true },
      { logo: csharp, name: "C#", focus: true },
      { logo: c, name: "C++", focus: false },
    ],
  ];

  const backendTools = [
    [
      { logo: aws, name: "Amazon Web Services", focus: false },
      { logo: gcp, name: "Google Cloud Platform", focus: false },
      { logo: node, name: "Node.js", focus: true },
      { logo: graphql, name: "GraphQL", focus: false },
      { logo: postman, name: "Postman", focus: true },
      { logo: express, name: "Express", focus: true },
      { logo: docker, name: "Docker", focus: false },
      { logo: firebase, name: "Firebase", focus: false },
      { logo: mongo, name: "MongoDB", focus: false },
      { logo: netlify, name: "Netlify", focus: true },
      { logo: heroku, name: "Heroku", focus: false },
      { logo: vercel, name: "Vercel", focus: true },
    ],
  ];

  const blockchain = [
    [
      { logo: ethers, name: "Ethers.js", focus: true },
      { logo: sol, name: "Solidity", focus: true },
      { logo: ganache, name: "Ganache", focus: true },
      { logo: truffle, name: "Truffle", focus: true },
      { logo: web3, name: "Web3", focus: true },
      { logo: infura, name: "Infura", focus: false },
      { logo: alchemy, name: "Alchemy", focus: true },
    ],
  ];

  document.addEventListener("scroll", function (e) {
    checkAnim("skills", "fadeIn");
    let el = document.getElementById("skills");
    let scrollPos = window.scrollY;
    let elementPos =
      window.pageYOffset + el.getBoundingClientRect().top + el.offsetHeight / 4;
    if (scrollPos + window.innerHeight > elementPos) {
      let classEls = el.getElementsByClassName("skill-block");
      for (let i = 0; i < classEls.length; i++) {
        if (!classEls[i].classList.contains("anim-done")) {
          classEls[i].classList.add("anim-done");
          popUp(classEls[i], i);
        }
      }
    }
  });

  function popUp(classEl, i) {
    classEl.animate(
      [
        { opacity: 0, transform: "scale(0)" },
        { opacity: 1, transform: "scale(1.4)", offset: 0.7 },
        { opacity: 1, transform: "scale(1)" },
      ],
      {
        duration: 600,
        delay: 100 + i * 15,
        easing: "ease",
        fill: "forwards",
      }
    );
  }

  return (
    <section id="skills" className="section-container opacity-zero">
      <Container fluid>
        <Row>
          <Col md={{ span: 12, offset: 0 }} className="col-container">
            <div id="text" className="text">
              <h2 className="section-title">Skills</h2>
              <h5 className="subtitle assisting-text">
                These are the technologies that I have worked with:
              </h5>
            </div>
            <div className="skill-displays">
              <SkillColumn
                name="Front End"
                arrays={[frontendFrameworks, frontendLanguages]}
                icon={"laptop"}
                subsecs={["Frameworks", "Languages"]}
              />
              <SkillColumn
                name="Back End"
                arrays={[backendTools, backendLanguages]}
                icon={"server"}
                subsecs={["Tools", "Languages"]}
              />
              {/* <Col className='skill-col'> */}
              <SkillColumn
                name="Blockchain"
                arrays={[blockchain]}
                icon={"cube"}
              />
              {/* <SkillColumn
                name="Development Tools"
                arrays={[devTools]}
                icon={"tools"}
              /> */}
              {/* </Col> */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

function SkillColumn({ name, arrays, subsecs, icon }) {
  return (
    <Col className="skill-col">
      <h3 className="skill-subtitle">
        <span className="display-icon">
          <i className={`fas fa-${icon} fa-sm`}></i>
        </span>
        {name}
      </h3>
      {arrays.map((array, i) => (
        <Fragment key={i}>
          <h6 className="assisting-text skill-sec">
            {subsecs ? subsecs[i] : null}
          </h6>
          {array.map((arraySec, j) => (
            <Row className="skill-list" key={j}>
              {arraySec.map((arrayItem, k) => (
                <div className="skill-block opacity-zero" key={(i, j, k)}>
                  <img className="skill-img" src={arrayItem.logo} />
                  <h6 className="skill-name peripheral-text">
                    {arrayItem.name}
                  </h6>
                </div>
              ))}
            </Row>
          ))}
        </Fragment>
      ))}
    </Col>
  );
}

export default SkillSection;
